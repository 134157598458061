<template>
  <v-container>
    <BaseForm
      :title="$route.meta.title"
      :loading="loading"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Llave"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Llave"
                placeholder="Llave"
                v-model="setting.key"
                dense
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Valor"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                v-if="setting.key == '2FA'"
                :items="twofa"
                label="Valor"
                placeholder="Valor"
                v-model="setting.value"
                dense
                :error-messages="errors[0]"
              />
              <v-text-field
                v-else-if="setting.type == 'integer'"
                type="number"
                label="Valor"
                placeholder="Valor"
                v-model="setting.value"
                dense
                :error-messages="errors[0]"
              />
              <v-select
                v-else-if="setting.type == 'boolean'"
                v-model="setting.value"
                label="Valor"
                :items="booleany"
                hide-details
                clearable
                dense
                single-line
                light
              ></v-select>
              <v-text-field
                v-else-if="setting.type == 'string' && setting.key != '2FA'"
                label="Valor"
                placeholder="Valor"
                v-model="setting.value"
                dense
                :error-messages="errors[0]"
              />
              <v-text-field
                v-else-if="setting.type == 'float'"
                label="Valor"
                placeholder="Valor"
                v-model="setting.value"
                dense
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              :items="types"
              label="Tipo"
              v-model="setting.type"
              dense
            ></v-select>
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import SettingService from '@/services/setting'

export default {
  components: { BaseForm },
  data() {
    return {
      loading: false,
      setting: { active: true },
      types: ['boolean', 'string', 'integer', 'float'],
      twofa: [
        'Acceso al portal (requerido desde el login)',
        'Instrucciones de transferencias (valor por defecto)',
        'No aplica'
      ],
      booleany: ['true', 'false']
    }
  },
  async created() {
    this.loading = true
    try {
      const id = this.$route.params.id
      if (id) {
        this.setting = await SettingService.find(id)
      }
    } catch (error) {
      console.log(error)
      this.$dialog.message.error('No se pudo cargar el registro.')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await SettingService.save(this.setting)
        this.$dialog.notify.success('Registro guardado con éxito.')
        this.$router.go(-1)
      } catch {
        this.$dialog.message.error('No se pudo guardar el registro.')
      }
    }
  }
}
</script>
