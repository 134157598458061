var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('BaseList',{attrs:{"color":"primary","title":_vm.$route.meta.title,"section":_vm.$route.meta.section,"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"totalRows":_vm.totalRows,"addText":"Agregar Usuario","subTitle":"Usuarios Registrados"},on:{"add":_vm.handleNew,"edit":_vm.handleEdit,"refresh":_vm.getData,"delete":_vm.handleDelete,"search":_vm.handleSearch,"options":_vm.handleOptions},scopedSlots:_vm._u([{key:"item.blocked",fn:function(ref){
var value = ref.value;
return [_c('span',{class:value ? 'red--text' : 'green--text'},[_vm._v(" "+_vm._s(value ? 'Bloqueado' : 'Activo')+" ")])]}},{key:"item.email_verified_at",fn:function(ref){
var value = ref.value;
return [_c('span',{class:value ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(value ? 'Verificado' : 'Sin Verificar')+" ")])]}},{key:"custom-button",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.first_name)?_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('reset-password'),expression:"'reset-password'"}],staticClass:"mr-2",attrs:{"icon":"","color":"#8990AD"},on:{"click":function($event){return _vm.handleReset(item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-lock-reset')}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Resetear")])])]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value,'DD/MM/YYYY'))+" ")])]}},{key:"item.updated_at",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value,'DD/MM/YYYY h:mm:ss a'))+" ")])]}},{key:"item.email_sent",fn:function(ref){
var value = ref.value;
return [_c('span',{class:value ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(value ? 'Enviado' : 'Sin Enviar')+" ")])]}},{key:"item.email",fn:function(ref){
var value = ref.value;
return [_c('a',{staticClass:"text-decoration-none",attrs:{"href":("mailto:" + value)}},[_vm._v(_vm._s(value))])]}},{key:"item.admin",fn:function(ref){
var value = ref.value;
return [_c('v-simple-checkbox',{attrs:{"value":value,"disabled":""}})]}},{key:"item.customer",fn:function(ref){
var value = ref.value;
return [_c('v-simple-checkbox',{attrs:{"value":value,"disabled":""}})]}},{key:"item.mobile_phone",fn:function(ref){
var value = ref.value;
return [_c('a',{staticClass:"text-decoration-none",attrs:{"href":("tel:" + value)}},[_vm._v(_vm._s(_vm._f("phone")(value)))])]}},{key:"item.broker_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.broker_id ? item.broker.name : ''))])]}},{key:"item.twofactor_habilitated",fn:function(ref){
var value = ref.value;
return [_c('v-simple-checkbox',{attrs:{"value":value,"disabled":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }