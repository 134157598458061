import BaseService from './base'
import axios from '@/plugins/axios'

export default class TranslationService extends BaseService {
  static resource() {
    return 'api/v1/translations'
  }

  static async post_collection(payload = {}) {
    const { data } = await axios.post(`api/v1/translations_collection`, payload)
    return data.data
  }
}
