<template>
  <v-container>
    <BaseList
      color="primary"
      :title="$route.meta.title"
      :section="$route.meta.section"
      :headers="headers"
      :items="users"
      :loading="loading"
      :totalRows="totalRows"
      addText="Agregar Usuario"
      subTitle="Usuarios Registrados"
      @add="handleNew"
      @edit="handleEdit"
      @refresh="getData"
      @delete="handleDelete"
      @search="handleSearch"
      @options="handleOptions"
    >
      <template #item.blocked="{ value }">
        <span :class="value ? 'red--text' : 'green--text'">
          {{ value ? 'Bloqueado' : 'Activo' }}
        </span>
      </template>
      <template #item.email_verified_at="{ value }">
        <span :class="value ? 'green--text' : 'red--text'">
          {{ value ? 'Verificado' : 'Sin Verificar' }}
        </span>
      </template>
      <template #custom-button="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              class="mr-2"
              icon
              color="#8990AD"
              v-on="on"
              v-can="'reset-password'"
              @click="handleReset(item)"
              v-if="item.first_name"
            >
              <v-icon v-text="'mdi-lock-reset'" />
            </v-btn>
          </template>
          <span>Resetear</span>
        </v-tooltip>
      </template>
      <template #item.created_at="{ value }">
        <span>
          {{ value | date('DD/MM/YYYY') }}
        </span>
      </template>
      <template #item.updated_at="{ value }">
        <span>
          {{ value | date('DD/MM/YYYY h:mm:ss a') }}
        </span>
      </template>
      <template #item.email_sent="{ value }">
        <span :class="value ? 'green--text' : 'red--text'">
          {{ value ? 'Enviado' : 'Sin Enviar' }}
        </span>
      </template>
      <template #item.email="{ value }">
        <a :href="`mailto:${value}`" class="text-decoration-none">{{
          value
        }}</a>
      </template>
      <template #item.admin="{ value }">
        <v-simple-checkbox
          :value="value"
          disabled
        />
      </template>
      <template #item.customer="{ value }">
        <v-simple-checkbox
          :value="value"
          disabled
        />
      </template>
      <template #item.mobile_phone="{ value }">
        <a :href="`tel:${value}`" class="text-decoration-none">{{
          value | phone
        }}</a>
      </template>
      <template #item.broker_id="{ item }">
        <span>{{ item.broker_id ? item.broker.name : '' }}</span>
      </template>
      <template #item.twofactor_habilitated="{ value }">
        <v-simple-checkbox
          :value="value"
          disabled
        />
      </template>
    </BaseList>
  </v-container>
</template>

<script>
import BaseList from '@/components/commons/BaseList'
import UserService from '@/services/user'
import AccountGroupsService from '@/services/external/accountGroups'
import AuthorityLevelsService from '@/services/external/authorityLevels'

export default {
  components: { BaseList },
  data() {
    return {
      options: {},
      loading: false,
      users: [],
      authorityLevels: [],
      accountGroups: [],
      totalRows: 0,
      search: '',
      message: '',
      headers: [
        { text: 'Nombres', value: 'first_name', width: '110px' },
        { text: 'Apellidos', value: 'last_name', width: '110px' },
        {
          text: 'Fecha',
          value: 'created_at',
          width: '100px',
          align: 'center'
        },
        { text: 'Correo Electrónico', value: 'email', width: '100px' },
        {
          text: 'Código Cuenta',
          value: 'accountCode',
          width: '125px'
          // sortable: false
        },
        { text: 'Admin', value: 'admin', align: 'center' },
        { text: 'Cliente', value: 'customer', align: 'center' },
        { text: 'Autoridad', value: 'authorityName'},
        { text: 'Grupo', value: 'groupName' },
        { text: 'Broker', value: 'broker_id', width: '90px', align: 'center' },
        { text: '2FA', value: 'twofactor_habilitated', width: '90px', align: 'center' },
        { text: 'Estatus', value: 'active', width: '90px', align: 'center' },
        {
          text: 'Bloqueado',
          value: 'blocked',
          width: '110px',
          align: 'center'
        },
        {
          text: 'Estatus Correo',
          value: 'email_sent',
          width: '120px',
          align: 'center'
        },
        {
          text: 'Email Verificado',
          value: 'email_verified_at',
          width: '120px',
          align: 'center'
        },
        {
          text: 'Modificado',
          value: 'updated_at',
          width: '120px'
        }
      ]
    }
  },
  async created() {
    this.loading = true
    try {
      await this.populateAuthorityLevels()
      await this.populateAccountGroups()
      await this.getData()
    } catch (error) {
      console.log('error', error);
    } finally {
      this.loading = false
    }
  },
  methods: {
    async getData() {
      if (!this.authorityLevels.length || !this.accountGroups.length) return

      const { itemsPerPage, page, sortBy, sortDesc } = this.options
      this.loading = true
      try {
        const { data } = await UserService.fetch(
          { 'filter[query]': this.search, 
            include: 'broker'
          }, 
          (sortBy[0] && sortBy[0] !== 'authorityName' && sortBy[0] !== 'groupName') ? sortBy[0] : 'first_name',
          sortDesc[0],
          page,
          itemsPerPage == -1 ? this.totalRows : itemsPerPage
        )
        this.users = data.data.map(item => {
          item.authorityName = this.authorityLevels.find(i => i.code == item.authorityLevelsCode)?.name
          item.groupName = this.accountGroups.find(i => i.code == item.groupCode)?.name
          
          return item
        })
        if( sortBy[0] == 'authorityName' || sortBy[0] == 'groupName' ) {
          this.users.sort((a, b) => {
            if(sortDesc[0]) [a, b] = [b, a] 
            return (a[sortBy[0]] || b[sortBy[0]]) ?
              (!a[sortBy[0]] ? -1 : !b[sortBy[0]] ? 1 : a[sortBy[0]].localeCompare(b[sortBy[0]]))
              : 0
          })
        }

        this.totalRows = data.total
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },

    handleNew() {
      this.$router.push({ name: 'UserCreate' })
    },

    handleEdit(item) {
      this.$router.push({ name: 'UserEdit', params: { id: item.id } })
    },

    async handleDelete(item) {
      const res = await this.$dialog.warning({
        text: '¿ Desea eliminar el registro ?'
      })

      if (!res) return false

      try {
        await UserService.delete(item.id)
        this.$dialog.notify.success('Registro eliminado con éxito.')
        await this.getData()
      } catch (e) {
        this.$dialog.message.error(e.title)
      }
    },
    async handleReset(item) {
      this.loading = true

      const res = await this.$dialog.warning({
        text: '¿ Desea restablecer la contraseña ?'
      })

      if (!res) return false

      try {
        let env = true
        if (!item.customer) {
          env = false
        }
        var data = await UserService.sendEmailReset(item.email, env)
        this.message = data.message
        this.$dialog.notify.success(this.message)
        await this.getData()
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },

    handleOptions(options) {
      this.options = options
      this.getData()
    },

    handleSearch(value) {
      this.search = value
      this.getData()
    },

    async populateAuthorityLevels() {
      const { data } = await AuthorityLevelsService.fetch()
      this.authorityLevels = data
    },

    async populateAccountGroups() {
      const { data } = await AccountGroupsService.fetch()
      this.accountGroups = data
    }
  }
}
</script>

<style></style>
