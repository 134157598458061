<template>
  <BaseList
    color="primary"
    :title="$route.meta.title"
    :section="$route.meta.section"
    :headers="headers"
    :items="settings"
    :loading="loading"
    :totalRows="totalRows"
    @add="handleNew"
    @edit="handleEdit"
    @refresh="getData"
    @delete="handleDelete"
    @search="handleSearch"
    @options="handleOptions"
  >
  </BaseList>
</template>

<script>
import BaseList from '@/components/commons/BaseList'
import SettingService from '@/services/setting'

export default {
  components: { BaseList },
  data() {
    return {
      options: {},
      loading: false,
      settings: [],
      totalRows: 0,
      search: '',
      headers: [
        { text: 'Llave', value: 'key' },
        { text: 'Valor', value: 'value' },
        { text: 'Tipo', value: 'type' },
        { text: 'Fecha de Creación', value: 'created_at' },
        { text: 'Fecha de Actualización', value: 'updated_at' }
      ]
    }
  },
  methods: {
    async getData() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.options

      this.loading = true
      try {
        const { data } = await SettingService.fetch(
          { 'filter[query]': this.search },
          sortBy[0] ? sortBy[0] : 'key',
          sortDesc[0],
          page,
          itemsPerPage == -1 ? this.totalRows : itemsPerPage
        )
        this.settings = data.data
        this.totalRows = data.total
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },

    handleNew() {
      this.$router.push({ name: 'SettingCreate' })
    },

    handleEdit(item) {
      this.$router.push({
        name: 'SettingEdit',
        params: { id: item.id }
      })
    },

    async handleDelete(item) {
      const res = await this.$dialog.warning({
        text: '¿ Desea eliminar el registro ?'
      })

      if (!res) return false

      try {
        await SettingService.delete(item.id)
        this.$dialog.notify.success('Registro eliminado con éxito.')
        await this.getData()
      } catch (e) {
        this.$dialog.message.error(e.title)
      }
    },

    handleOptions(options) {
      this.options = options
      this.getData()
    },

    handleSearch(value) {
      this.search = value
      this.getData()
    }
  }
}
</script>

<style></style>
