<template>
  <BaseList
    color="primary"
    :title="$route.meta.title"
    :section="$route.meta.section"
    :headers="headers"
    :items="questions"
    :loading="loading"
    :totalRows="totalRows"
    addText="Agregar Pregunta"
    :search="search"
    @add="handleNew"
    @edit="handleEdit"
    @refresh="getData"
    @delete="handleDelete"
    @search="handleSearch"
    @options="handleOptions"
  >
  </BaseList>
</template>
<script>
import BaseList from '@/components/commons/BaseList'
import QuestionService from '@/services/question'
import TranslationService from '@/services/translation'

export default {
  components: { BaseList },
  data() {
    return {
      options: {},
      loading: false,
      questions: [],
      totalRows: 0,
      search: '',
      headers: [
        { text: 'Pregunta', value: 'translation' },
        { text: 'Fecha de Creación', value: 'created_at' },
        { text: 'Status', value: 'active' }
      ],
      translations: []
    }
  },
  methods: {
    async getQuestionsTranslations() {
      const { data } = await TranslationService.fetch({
        'filter[category]': 'Preguntas de Seguridad',
        'filter[language_id]': 2
      })
      this.translations = data.data
    },
    async getData() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.options
      this.loading = true
      try {
        const { data } = await QuestionService.fetch(
          { 'filter[translations]': this.search,
            include: 'translations'
          },
          sortBy[0] ? sortBy[0] : '',
          sortDesc[0],
          page,
          itemsPerPage == -1 ? this.totalRows : itemsPerPage
        )
        this.questions = data.data.map(item => {
          const translation = item?.translations.find(i => i.language_id == 2)
          item.translation = translation?.translation
          return item
        })
        this.totalRows = data.total

      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },

    handleNew() {
      this.$router.push({ name: 'QuestionCreate' })
    },

    handleEdit(item) {
      this.$router.push({
        name: 'QuestionEdit',
        params: { id: item.id }
      })
    },

    async handleDelete(item) {
      const res = await this.$dialog.warning({
        text: '¿ Desea eliminar el registro ?'
      })

      if (!res) return false

      try {
        await QuestionService.delete(item.id)
        this.$dialog.notify.success('Registro eliminado con éxito.')
        await this.getData()
      } catch (e) {
        this.$dialog.message.error(e.title)
      }
    },

    handleOptions(options) {
      this.options = options
      this.getData()
    },

    handleSearch(value) {
      this.search = value
      this.getData()
    }
  }
}
</script>

<style></style>
