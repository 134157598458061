<template>
  <v-container>
    <BaseForm
      :title="$route.meta.title"
      :loading="loading"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-row>
          <v-col v-for="language in question.languages" :key="language.index" cols="12">
            <ValidationProvider
              :name="`Pregunta en el idioma: ${language.name}`"
              rules="required|min:5|max:255"
              :min_value="5"
              v-slot="{ errors }"
            >
              <v-text-field
                :label="`Pregunta en el idioma: ${language.name}`"
                placeholder="Pregunta de seguridad"
                v-model="language.translateQuestion.translation"
                dense
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12 d-flex justify-end">
            <v-switch
              v-model="question.active"
              color="primary"
              label="Activo"
            />
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import QuestionService from '@/services/question'
import LanguageService from '@/services/language'
import TranslationService from '@/services/translation'

export default {
  components: { BaseForm },
  data() {
    return {
      loading: false,
      question: { 
        active: true,
        languages: []
      },
      translations: []
    }
  },
  async created() {
    this.loading = true
    try {
      const id = this.$route.params.id
      if (id) {
        this.question = await QuestionService.find(id)
      }
      await this.getQuestionsTranslations()
      await this.getLanguages()
    } catch (error) {
      console.log(error)
      this.$dialog.message.error('No se pudo cargar el registro.')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async getQuestionsTranslations() {
      const { data } = await TranslationService.fetch({
        'filter[category]': 'Preguntas de Seguridad'
      })
      this.translations = data.data
    },
    async getLanguages() {
      const { data } = await LanguageService.fetch({})
      this.question.languages = data.data.map(item => {
        item.translateQuestion = this.question?.question ? this.translations.find(i => i.label == this.question?.question && i.language_id == item.id) : {}
        return item
      })
    },
    async handleSubmit() {
      try {
        await QuestionService.save(this.question)
        this.$dialog.notify.success('Registro guardado con éxito.')
        this.$router.go(-1)
      } catch {
        this.$dialog.message.error('No se pudo guardar el registro.')
      }
    }
  }
}
</script>
